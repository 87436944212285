import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleUp, faArrowCircleDown, faArrowUp, faEquals } from '@fortawesome/free-solid-svg-icons'
import '../css/analysis_tab.css'
import Account_AttentionPoints from './Account_AttentionPoints';
import analysisLayout from '../jsons/analysis_overview.json'
import { useEffect, useState } from 'react';





export default function Account_Analysis({ trends }) {

    const [allTables, setAllTables] = useState([])

    useEffect(() => {
        let all_tables = []
        if (trends != null & trends.length>0) {
            let analysis_tables = []
            let analysis_table = []

            //INTERATE OVER ARRAY FOR RENDERING ALL TABLES
            analysisLayout.map((table, index) => {

                //INTERATE FOR HEADER
                let header = []
                table['metrics'].map((metric, metric_index) => {
                    if ( metric_index == 0) {

                        header.push(<td></td>)
                    }
                    header.push(<td>{metric['display as']}</td>)

                })

                //INTERATE FOR BODY
                let body_variation = []
                let body_trend = []

                //LOOP THROUGH EACH METRIC
                table['metrics'].map((metric, metric_index) => {
                    let calc_trend_metric
                    let metric_variation_icon
                    let metric_trend_icon

                    calc_trend_metric = (trends.find((value) => {
                        return value['METRIC'] == metric['metric']
                    }))

                    let metric_variation_value = ((+calc_trend_metric['Variation']))
                    let metric_trend_value = ((+calc_trend_metric['Linear_Coef']))


                    //TREATING THE ICON FOR VARIATION
                    if (metric['isUpwardBetter'] == true) {
                        if (metric_variation_value >= 1) {
                            metric_variation_icon = <span className='green_arrow'><FontAwesomeIcon icon={faArrowCircleUp} /></span>
                        }
                        else if (metric_variation_value <= -1) {
                            metric_variation_icon = <span className='red_arrow'><FontAwesomeIcon icon={faArrowCircleDown} /></span>
                        }
                        else {
                            metric_variation_icon = <span className='noColor'><FontAwesomeIcon icon={faEquals} /></span>
                        }
                    }
                    else {
                        if (metric_variation_value <= -1) {
                            metric_variation_icon = <span className='green_arrow'><FontAwesomeIcon icon={faArrowCircleDown} /></span>
                        }
                        else if (metric_variation_value >= 1) {
                            metric_variation_icon = <span className='red_arrow'><FontAwesomeIcon icon={faArrowCircleUp} /></span>
                        }
                        else {
                            metric_variation_icon = <span className='noColor'><FontAwesomeIcon icon={faEquals} /></span>
                        }
                    }

                    //TREATING THE ICON FOR TREND
                    if (metric['isUpwardBetter'] == true) {
                        if (metric_trend_value >= 1) {
                            metric_trend_icon = <span className='green_arrow'><FontAwesomeIcon icon={faArrowUp} className='rotate45' /></span>
                        }
                        else if (metric_trend_value <= -1) {
                            metric_trend_icon = <span className='red_arrow'><FontAwesomeIcon icon={faArrowUp} className='rotate135' /></span>
                        }
                        else {
                            metric_trend_icon = <span className='noColor'><FontAwesomeIcon icon={faEquals} /></span>
                        }
                    }
                    else {
                        if (metric_trend_value < -1) {
                            metric_trend_icon = <span className='green_arrow'><FontAwesomeIcon icon={faArrowUp} className='rotate135' /></span>
                        }
                        else if (metric_trend_value > 1) {
                            metric_trend_icon = <span className='red_arrow'><FontAwesomeIcon icon={faArrowUp} className='rotate45' /></span>
                        }
                        else {
                            metric_trend_icon = <span className='noColor'><FontAwesomeIcon icon={faEquals} /></span>
                        }
                    }

                    //just add lebels to first table
                    if ( metric_index == 0) {
                        body_variation.push(<td className='table_row_title'>MoM</td>)
                    }
                    body_variation.push(<td>{metric_variation_icon}{Math.round(+metric_variation_value)}%</td>)

                    if ( metric_index == 0) {
                        body_trend.push(<td className='table_row_title'>12 MONTH TREND</td>)
                    }
                    body_trend.push(<td>{metric_trend_icon}</td>)

                })


                analysis_table = (<div className='analysis_category'>
                    <div className='analysis_title'>{table['table name']}</div>
                    <div className='analysis_body'>
                        <Table bordered size='sm'>
                            <tbody>
                                <tr>
                                    {header}
                                </tr>
                                <tr>
                                    {body_variation}
                                </tr>
                                <tr>
                                    {body_trend}
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
                )

                all_tables.push(analysis_table)
            })


        }
        setAllTables(<>{all_tables}</>)
    }, [])

    let analysis_Int_Values = {}

    //SET UP TEXTs DEPENDING ON VARIABLES
    if (trends != null & trends.length>0) {
        let items = (trends)
        for (let i = 0; i < items.length; i++) {
            analysis_Int_Values[(items[i]['METRIC']).replace(' ', '_') + '_Linear_Coef'] = (Math.round(+items[i]['Linear_Coef']))
            analysis_Int_Values[(items[i]['METRIC']).replace(' ', '_') + '_Variation'] = (Math.round(+items[i]['Variation']))
            //VARIATION

            analysis_Int_Values['Support_Risk_Scorecard'] = items[i]['Support_Risk_scorecard']
            analysis_Int_Values['Adoption_Breadth_Scorecard'] = items[i]['Adoption_breadth_scorecard']
            analysis_Int_Values['Predictive_Renewal_Scorecard'] = items[i]['Predictive_Renewal_scorecard']

        }
    }

    

    return (
        trends.length > 0 ? (
            <div className='analysis_section'>
                <div className='analysis_card'>
                    <div className='analysis_mom_trend_title'>MoM Comparison and Trends</div>
                    <div className='accordion_block'>
                        <div className='accordion_body'>
                            {allTables}
                            <div className='analysis_body_cards'>
                                <div className={'card_scores ' + analysis_Int_Values["Support_Risk_Scorecard"].toLowerCase()}>
                                    <span className={'text_analysis bold ' + analysis_Int_Values["Support_Risk_Scorecard"].toLowerCase()}>Support Risk Score <br /> {(analysis_Int_Values['Support_Risk_Scorecard']) !== 0 ? (analysis_Int_Values['Support_Risk_Scorecard']) : "None"}</span>
                                </div>
                                <div className={'card_scores ' + analysis_Int_Values["Predictive_Renewal_Scorecard"].toLowerCase()}>
                                    <span className={'text_analysis bold ' + analysis_Int_Values["Predictive_Renewal_Scorecard"].toLowerCase()}>Predictive Renewal <br /> {(analysis_Int_Values['Predictive_Renewal_Scorecard']) !== 0 ? (analysis_Int_Values['Predictive_Renewal_Scorecard']) : "None"}</span>
                                </div>
                                <div className={'card_scores ' + analysis_Int_Values["Adoption_Breadth_Scorecard"].toLowerCase()}>
                                    <span className={'text_analysis bold ' + analysis_Int_Values["Adoption_Breadth_Scorecard"].toLowerCase()}>Adoption Breadth <br /> {(analysis_Int_Values['Adoption_Breadth_Scorecard']) !== 0 ? (analysis_Int_Values['Adoption_Breadth_Scorecard']) : "None"}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Account_AttentionPoints analysis_Int_Values={analysis_Int_Values} />
            </div>
        ) : (
            <div className='analysis_mom_trend_title'>Insufficient data in the account to generate analysis.</div>
        )
    );
}