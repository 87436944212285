import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import '../css/adoption_breadth_customer.css'
import { useEffect, useState } from 'react';
import { send_adoption_breadth_inputs, get_adoption_breadth_inputs } from '../services/analysis';
import { TableauViz, TableauEventType } from '@tableau/embedding-api';
import C360Logo from '../components/C360Logo'
import { parseJwt, get_OktaToken } from '../scripts/helpers';
import { useOktaAuth } from '@okta/okta-react';
import AdoptionBreadthTopForm from '../components/adoption_breadth_top_form';
import AdoptionBreadthFeatureForm from '../components/adoption_breadth_feature_form';
import ab_dashboards from '../jsons/adoption_breadth_dashboards.json'

const enviroment = process.env.REACT_APP_DEPLOY_ENV;
let url = ab_dashboards[enviroment]['customer']


export default function Adoption_breadth_customer() {
  const [spinner, setSpinner] = useState(true)
  const [changeLock, setChangeLock] = useState(true)
  const [userCanSee, setUserCanSee] = useState(true) // hide form
  const [userCanEdit, setUserCanEdit] = useState(true) // disable buttons 
  const [accountName, setAccountName] = useState('')
  const { authState } = useOktaAuth();

  useEffect(() => {
    async function update_entries(tableauViz) {
      let account_name = await get_Value('Apply Button', 'Account Name')
      setAccountName(account_name)

    }
    const tableauViz = document.getElementById('tableauViz');
    tableauViz.addEventListener(TableauEventType.FirstInteractive, async function () {
      update_entries(tableauViz)
    });
    tableauViz.addEventListener(TableauEventType.ParameterChanged, async function () {
      update_entries(tableauViz)
    });


  }, [])


  function getUserEmail() {
    const oktaToken = get_OktaToken(authState)
    const parsedToken = parseJwt(oktaToken)
    const userEmail = parsedToken['sub']
    return userEmail
  }


  async function get_Value(worksheetPage, fieldName) {
    const viz = document.getElementById('tableauViz');
    let sheets = viz.workbook.activeSheet.worksheets
    for (let i = 0; i < sheets.length; i++) {
      if (sheets[i].name == worksheetPage) {
        const sheetFilters = await sheets[i].getFiltersAsync();
        for (let i = 0; i < sheetFilters.length; i++) {
          if (sheetFilters[i].fieldName == fieldName) {

            return (sheetFilters[i]['appliedValues'][0]['nativeValue'])
          }
        }
      }
    }
  }


  return (
    <>
      <div className="page_section">

        <div className='top-section mb-3'>
          <div className='filters'>
            <C360Logo />

            <div className='select-box'>

            </div>
          </div>
        </div>
        <div className='dashboard_container dashboard_forms '>
          <div className='dashboard'>
            <tableau-viz id="tableauViz"
              src={url} width="100%" toolbar="hidden" hide-tabs>
            </tableau-viz>
          </div>

          <div className='reporting_forms'>

            {
              spinner == true ? <div><div className='spinnerclass'><Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner> <span className='spinner_text'>Loading comments ...</span></div></div> : ''

            }
            {userCanSee === true ?
              <div classname='all_features' hidden={spinner}>
                <AdoptionBreadthFeatureForm changeLock={changeLock} setChangeLock={setChangeLock} userCanEdit={userCanEdit} accountName={accountName} getUserEmail={getUserEmail} authState={authState} setSpinner={setSpinner}/>

              </div>
              : ''}
          </div>

        </div>
      </div>
    </>
  )
}

