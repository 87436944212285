import Header from "./components/Navbar"
import { useNavigate } from "react-router-dom"
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import {  Security } from '@okta/okta-react';
import AppRoutes from "./components/Routes";


const oktaAuth = new OktaAuth({
  issuer: 'https://genesys.okta.com/oauth2/default',
  clientId: '0oa1xiugyqxxQMCU30h8',
  redirectUri: window.location.origin + '/login/callback'
});


const App = () => {

  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Header />
        <div className="body_container">
          <main>
            <AppRoutes />
          </main>
        </div>
      </Security >
      </>
      )
}

      export default App;