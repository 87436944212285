import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../css/feedback_modal.css'
import { send_feedback } from '../services/feedback';
import { useOktaAuth } from '@okta/okta-react';
import Spinner from 'react-bootstrap/Spinner';

export const FeedbackModal = ({ modalShow, handleClose,useLocation }) => {
    const [feedbackType, setFeedbackType] = useState('')
    const [Comment, setComment] = useState('')
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [formVisible, setFormVisible] = useState(true)
    const { authState } = useOktaAuth();
    const location=useLocation()


    useEffect(() => {
        //clear modal on Open
        if (modalShow == true) {
            setFormVisible(true)
            setFeedbackType('')
            setComment('')
            setButtonDisabled(false)
        }
    }, [modalShow]);


    
    const handleSubmit = async () => {
        if (feedbackType.length==0 || Comment.length==0  ){
            alert ('Please, fill out both fields')
            return
        }
        const page=location.pathname.replace('/','')==''?'Home':location.pathname.replace('/','')
        const tab=location.search.replace("?tab=",'')
        setButtonDisabled(true)
        const response=await send_feedback(authState,feedbackType,Comment,page+tab)
        if (response==true){
            setFormVisible(false)
        }
        else{
            alert('An error occurred, please try again later!')
            handleClose()
        }
        
    }


    return (
        <Modal show={modalShow} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Feedback</Modal.Title>
            </Modal.Header>

            {formVisible === true ?
                <>
                    <Modal.Body>
                        Please, select the feedback type and add your comment below.<br /><br />
                        <Form>
                            <Form.Select onChange={(e) => setFeedbackType(e.target.value)} value={feedbackType} placeholder='Select' required disabled={buttonDisabled}>
                                <option key='blankChoice' hidden value=''>Select</option>
                                <option key='bug' value='Bug'>Report Bug / Wrong metric value</option>
                                <option key='improvement' value='Improvement'>Improvement / Suggestion</option>
                                <option key='positive' value="Positive">Positive Feedback</option>
                            </Form.Select>
                            <Form.Control as="textarea" value={Comment} onChange={function (e) {
                                e.preventDefault()
                                setComment(e.target.value)
                            }} rows={15} required maxLength={2000} disabled={buttonDisabled}/>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant={buttonDisabled ? 'secondary': 'primary'} onClick={handleSubmit} disabled={buttonDisabled}>
                            {buttonDisabled ? <><Spinner/> Sending...</>: 'Submit'}
                        </Button>
                    </Modal.Footer>
                </> : <>
                    <Modal.Body>
                        <span className='checkMark'><FontAwesomeIcon icon={faCheckCircle} /></span>
                        <span className='feedbackSentText'> Feedback sent!</span> <br />
                        <span className='feedbackContactText'> One of our team members will contact you.</span>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </>
            }
        </Modal >)
}
