import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import "./css/style.css"
import { BrowserRouter } from "react-router-dom"
import 'bootstrap/dist/css/bootstrap.min.css';
import { ContextProvider } from "./context/Contexts"
import 'typeface-roboto'

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <ContextProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ContextProvider>
)