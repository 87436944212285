import { Link, useLocation } from "react-router-dom"
import "../css/header.css"
import "../scripts/header.js"
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import navbar_json from "../jsons/navbar.json"
import { useOktaAuth } from '@okta/okta-react';
import { Context } from "../context/Contexts.js";
import { useContext, useState } from "react";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FeedbackModal } from "./FeedbackModal.js";
import logo from "../imgs/genesys_logo.svg"
import feedback from "../imgs/feedback icon.svg"

const MenuItems = () => {
  const location = useLocation();
  const menuShow = (mItems) => {
    let target = '_self'
    return mItems.map(
      (item, index) => {
        if (item.submenu) {
          return (
            <NavDropdown
              title={
                item.label
              }
              key={index}
              className="dropdown-menu-dark  
                                   dropend">
              {menuShow(
                item.submenu
              )}
            </NavDropdown>
          );
        } else {
          target = item?.new_tab == true ? '_blank' : '_self'
          return (
            <Nav.Link
              href={
                item.url

              }
              target={target}
              className={location.pathname == item.url ? 'last-navbar-level active' : 'last-navbar-level'}
              key={index}

            >
              {item.label}
            </Nav.Link>
          );
        }
      }
    );
  };
  return (
    <Navbar
    >
      <Navbar.Brand href="#home">
        <Link to="/" className="nav-link-logo">
          <span className="logo">

            <img
              src={logo}
              className="customImgSize"
              alt="Genesys"
            />
          </span>
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav
          className="mr-auto"
        >
          {menuShow(
            navbar_json
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}


export default function Header() {
  const { authState } = useOktaAuth();
  const { oktaActivated } = useContext(Context);

  //Modal States
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);


  if (!authState?.isAuthenticated & oktaActivated == true) {
    return <></>
  }
  return (
    <>
      <div className="top_navbar">
        <MenuItems />
        <div className="external_help_container">
          <div className="help_container" onClick={handleShow}>

            <img
              src={feedback}
              className="feedback_icon"
              alt="Genesys"
            /></div>
        </div>

      </div>

      <FeedbackModal modalShow={modalShow} handleClose={handleClose} useLocation={useLocation} />
    </>
  );
};


