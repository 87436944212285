import Form from 'react-bootstrap/Form';
import React, { useState, useEffect } from 'react';
import jsonData from "../jsons/adoption_breadth_features.json"
import Button from 'react-bootstrap/Button';
import { get_adoption_breadth_inputs, send_adoption_breadth_inputs } from '../services/analysis';


export default function AdoptionBreadthFeatureForm({ changeLock, setChangeLock, userCanEdit, accountName, getUserEmail, authState, setSpinner }) {

    const [formValues, setFormValues] = useState({});
    const [buttonText, setButtonText] = useState('Edit');
    const [buttonVariant, setButtonVariant] = useState('primary');
    const [sending, setSending] = useState(false);
    const [updatedAt, setUpdatedAt] = useState('');
    // Initialize state based on JSON data or predefined keys
    useEffect(() => {
        const initialFormValues = {};

        jsonData.top_section.forEach(item => {
            const key = Object.keys(item)[0];
            initialFormValues[key] = '';
        });

        Object.keys(jsonData.features).forEach(category => {
            jsonData.features[category].forEach(item => {
                if (item.features) {
                    item.features.forEach(feature => {
                        const key = Object.keys(feature)[0];
                        initialFormValues[key] = {
                            reason_status: '',
                            issues_selector: '',
                            date_selector: '',
                            competitor_selector: '',
                            reason_selector: '',
                            comments_text_box: ''
                        };
                    });
                }
            });
        });

        setFormValues(initialFormValues);
    }, []);


    useEffect(() => {
        if (accountName.length > 1) {
            async function update_form() {
                setSpinner(true)
                let response = await get_adoption_breadth_inputs(accountName, authState)
                updateFormFieldsFromDatabase(response)
                scrollToTop()
                setSpinner(false)

            }
            update_form()
            
        }

    }, [accountName]);


    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // for smooth scrolling
        });
    };


    // Handle input change
    const handleInputChange = (key, field, value) => {
        if (typeof formValues[key] === 'object') {
            setFormValues(prevValues => ({
                ...prevValues,
                [key]: {
                    ...prevValues[key],
                    [field]: value
                }
            }));
        } else {
            setFormValues(prevValues => ({
                ...prevValues,
                [key]: value
            }));
        }
    };


    const handleEditClick = () => {
        if (changeLock) {
            setChangeLock(false);
            setButtonText('Discard');
            setButtonVariant('danger');
        } else {
            setChangeLock(true);
            setButtonText('Edit');
            setButtonVariant('primary');
        }
    };

    const handleSubmit = async () => {
        setSending(true)
        let userEmail = getUserEmail()
        let response = await send_adoption_breadth_inputs(accountName, formValues, authState, userEmail)
        if (response == true) {
            alert("Data Inserted")
            handleEditClick()
            //handleClearFields()
        }
        setSending(false)
    }

    const handleClearFields = () => {
        const clearedFormValues = {};

        jsonData.top_section.forEach(item => {
            const key = Object.keys(item)[0];
            clearedFormValues[key] = '';
        });

        Object.keys(jsonData.features).forEach(category => {
            jsonData.features[category].forEach(item => {
                if (item.features) {
                    item.features.forEach(feature => {
                        const key = Object.keys(feature)[0];
                        clearedFormValues[key] = {
                            reason_status: '',
                            issues_selector: '',
                            date_selector: '',
                            competitor_selector: '',
                            reason_selector: '',
                            comments_text_box: ''
                        };
                    });
                }
            });
        });

        setFormValues(clearedFormValues);
    };

    const updateFormFieldsFromDatabase = (databaseData) => {
        try {
            if (databaseData.length > 0) {

                const { ENTRIES, UPDATED_AT } = databaseData[0];
                const parsedEntries = JSON.parse(ENTRIES);

                // Ensure the structure matches the expected formValues structure
                const updatedFormValues = {};
                setUpdatedAt(UPDATED_AT)
                jsonData.top_section.forEach(item => {
                    const key = Object.keys(item)[0];
                    updatedFormValues[key] = parsedEntries[key] || '';
                });

                Object.keys(jsonData.features).forEach(category => {
                    jsonData.features[category].forEach(item => {
                        if (item.features) {
                            item.features.forEach(feature => {
                                const key = Object.keys(feature)[0];
                                updatedFormValues[key] = parsedEntries[key] || {
                                    reason_status: '',
                                    issues_selector: '',
                                    date_selector: '',
                                    competitor_selector: '',
                                    reason_selector: '',
                                    comments_text_box: ''
                                };
                            });
                        }
                    });
                });

                setFormValues(updatedFormValues);

            }
            else {
                handleClearFields()
            }

        } catch (error) {
            console.error('Failed to parse database JSON:', error);
        }
    };

    return (
        <div>
            <div className="top_form">
                {jsonData.top_section.map(item => {
                    const key = Object.keys(item)[0];
                    const height = item[key];
                    return (
                        <div className='form-top'>
                            <div key={key} style={{ height }}>
                                <Form.Group>
                                    <Form.Label>{key.replace('_', ' ')}</Form.Label>
                                    <Form.Control
                                        className='comments_text_box'
                                        as="textarea"
                                        rows={3}
                                        value={formValues[key] || ''}
                                        onChange={e => handleInputChange(key, null, e.target.value)}
                                        disabled={changeLock}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className='updated_at'>Last Updated on: {updatedAt.slice(0, 10)}</div>
            <div className="features_container" style={{ position: 'absolute', top: jsonData.feature_start_absolute_position }}>
                {Object.keys(jsonData.features).map(category => {
                    const section = jsonData.features[category];
                    const sectionTitleHeight = section.find(item => item.section_title_height).section_title_height;
                    const gap = section.find(item => item.gap).gap;

                    return (
                        <div key={category} style={{ marginTop: sectionTitleHeight }}>

                            {section.find(item => item.features).features.map((feature, index) => {
                                const key = Object.keys(feature)[0];
                                const height = feature[key];
                                return (
                                    <div className='feature_box' style={{ height }} >
                                        <Form key={key} className="feature_form" style={{ paddingBottom: gap }}>

                                            <Form.Select
                                                className='reason_status selector'
                                                onChange={e => handleInputChange(key, 'reason_status', e.target.value)}
                                                value={formValues[key]?.reason_status || ''}
                                                placeholder='Select'
                                                disabled={changeLock}
                                            >
                                                <option>Select</option>
                                                <option value='Active use - no issue'>Active use - no issue</option>
                                                <option value='Active use - issue'>Active use - issue</option>
                                                <option value="Planned future deployment">Planned future deployment</option>
                                                <option value="Using competitor">Using competitor</option>
                                                <option value="Not using">Not Using</option>
                                            </Form.Select>

                                            {formValues[key]?.reason_status === 'Active use - issue' && (
                                                <Form.Select
                                                    className='issues_selector selector'
                                                    onChange={e => handleInputChange(key, 'issues_selector', e.target.value)}
                                                    value={formValues[key]?.issues_selector || ''}
                                                    placeholder='Select'
                                                    disabled={changeLock}
                                                >
                                                    <option>Select issue</option>
                                                    <option value='Feature limitations'>Feature limitations</option>
                                                    <option value='3rd party or integration issues'>3rd party or integration issues</option>
                                                    <option value='Performance / reliability issues'>Performance / reliability issues</option>
                                                    <option value='User experience'>User experience</option>
                                                    <option value='Customer constraints'>Customer constraints</option>
                                                    <option value='Misaligned customer expectations'>Misaligned customer expectations</option>
                                                    <option value='Security and compliance concerns'>Security and compliance concerns</option>
                                                    <option value='Change in business strategy'>Change in business strategy</option>
                                                    <option value='Services / partner execution Issues'>Services / partner execution Issues</option>
                                                    <option value='Other (provide detail)'>Other (provide detail)</option>
                                                </Form.Select>
                                            )}

                                            {formValues[key]?.reason_status === 'Planned future deployment' && (
                                                <Form.Control
                                                    type="month"
                                                    className='date_selector selector '
                                                    onChange={e => handleInputChange(key, 'date_selector', e.target.value)}
                                                    value={formValues[key]?.date_selector || ''}
                                                    disabled={changeLock}
                                                />
                                            )}

                                            {formValues[key]?.reason_status === 'Using competitor' && (
                                                <Form.Select
                                                    className='competitor_selector selector'
                                                    onChange={e => handleInputChange(key, 'competitor_selector', e.target.value)}
                                                    value={formValues[key]?.competitor_selector || ''}
                                                    placeholder='Select'
                                                    disabled={changeLock}
                                                >
                                                    <option>Select competitor</option>
                                                    <option value='Alvaria'>Alvaria</option>
                                                    <option value='Amazon Connect'>Amazon Connect</option>
                                                    <option value='Avaya'>Avaya</option>
                                                    <option value='Calabrio'>Calabrio</option>
                                                    <option value='Clevertar'>Clevertar</option>
                                                    <option value='FloatBot'>FloatBot</option>
                                                    <option value='Freshchat'>Freshchat</option>
                                                    <option value='Freshdesk'>Freshdesk</option>
                                                    <option value='Google'>Google</option>
                                                    <option value='Kanda'>Kanda</option>
                                                    <option value='Konnect Insights'>Konnect Insights</option>
                                                    <option value='Kore.AI'>Kore.AI</option>
                                                    <option value='LINE'>LINE</option>
                                                    <option value='LivePerson'>LivePerson</option>
                                                    <option value='LivePro'>LivePro</option>
                                                    <option value='Microsoft'>Microsoft</option>
                                                    <option value='NICE'>NICE</option>
                                                    <option value='NTT'>NTT</option>
                                                    <option value='Nuance'>Nuance</option>
                                                    <option value='PEGA'>PEGA</option>
                                                    <option value='PKSHA'>PKSHA</option>
                                                    <option value='Salesforce'>Salesforce</option>
                                                    <option value='Skit.AI'>Skit.AI</option>
                                                    <option value='Sprinklr'>Sprinklr</option>
                                                    <option value='Typhoon'>Typhoon</option>
                                                    <option value='Ubona.AI'>Ubona.AI</option>
                                                    <option value='Verint'>Verint</option>
                                                    <option value='Voxtron'>Voxtron</option>
                                                    <option value='Wiz AI'>Wiz AI</option>
                                                    <option value='Yellow.AI'>Yellow.AI</option>
                                                    <option value='Zanroo'>Zanroo</option>
                                                    <option value='Zendesk'>Zendesk</option>
                                                    <option value='Zoho'>Zoho</option>
                                                    <option value='Other (provide detail)'>Other (provide detail)</option>
                                                </Form.Select>
                                            )}

                                            {formValues[key]?.reason_status === 'Not using' && (
                                                <Form.Select
                                                    className='reason_selector selector'
                                                    onChange={e => handleInputChange(key, 'reason_selector', e.target.value)}
                                                    value={formValues[key]?.reason_selector || ''}
                                                    placeholder='Select'
                                                    disabled={changeLock}
                                                >
                                                    <option>Select Reason</option>
                                                    <option value='Not aligned to customer strategy'>Not aligned to customer strategy</option>
                                                    <option value='Value perception issues'>Value perception issues</option>
                                                    <option value='Technical & functional limitations'>Technical & functional limitations</option>
                                                    <option value='Usability challenges'>Usability challenges</option>
                                                    <option value='Customer constraints'>Customer constraints</option>
                                                    <option value='Customer knowledge gaps'>Customer knowledge gaps</option>
                                                    <option value='Compliance limitation'>Compliance limitation</option>
                                                    <option value='Uncertain factors'>Uncertain factors</option>
                                                    <option value='Other (provide detail)'>Other (provide detail)</option>
                                                </Form.Select>
                                            )}
                                            <Form.Control
                                                className='comments_text_box'
                                                as="textarea"
                                                value={formValues[key]?.comments_text_box || ''}
                                                onChange={e => handleInputChange(key, 'comments_text_box', e.target.value)}
                                                rows={4}
                                                disabled={changeLock}
                                            />


                                        </Form>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
            {userCanEdit === true ?
                <div className="floating-button">
                    <Button variant={buttonVariant} disabled={sending} onClick={handleEditClick}>
                        {buttonText}
                    </Button>
                    <Button variant="success" disabled={sending} onClick={handleSubmit}>
                        {sending ? (
                            <>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                {' '}Saving...
                            </>
                        ) : (
                            'Save'
                        )}
                    </Button>
                </div>
                : ''}
        </div>
    );
};