import C360Logo from '../components/C360Logo'
import ab_dashboards from '../jsons/adoption_breadth_dashboards.json'

const enviroment = process.env.REACT_APP_DEPLOY_ENV;
let url = ab_dashboards[enviroment]['aggregate']
export default function Adoption_breadth_aggregate() {
  return (
    <>
      <div className="page_section">
      <div className='top-section mb-3'>
          <div className='filters'>
          <C360Logo/>

            <div className='select-box'>

            </div>
          </div>
        </div>
        <div className='dashboard_container center'>
          <div>
          <tableau-viz id="tableauViz"
            src={url} width="100%" toolbar="bottom" hide-tabs>
          </tableau-viz>
          </div>

        </div>
      </div>
    </>
  )
  }
