import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Select from 'react-select-virtualized';
import {  useState } from 'react';
import '../css/account_modal.css'

export const AccountModalSelector = ({ accountModalShow, setAccountModalShow, setAccountID, setAccountName, options,isLoadingAccounts,setProceedLoadAccount }) => {
    const [accountIDInt, setAccountIDInt] = useState('')
    const [accountNameInt, setAccountNameInt] = useState('')



    const handleSubmit = async () => {
        setAccountID(accountIDInt)
        setAccountName(accountNameInt)
        setAccountModalShow(false)
        setProceedLoadAccount(true)
    }


    return (
        <Modal show={accountModalShow} dialogClassName="account_modal" centered>
            <Modal.Header>
                <Modal.Title>Account Selector</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Please, select an account to proceed<br /><br />
                <Form>
                    <Select options={options} onChange={e => {

                        setAccountNameInt(e.label)
                        setAccountIDInt(e.value)
                    }} classNamePrefix="custom-select" isLoading={isLoadingAccounts} loadingMessage={() => 'Loading accounts. Please wait...'}  />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={handleSubmit} >
                    Submit
                </Button>
            </Modal.Footer>

        </Modal >)
}
