import { TableauViz, TableauEventType, FilterType } from '@tableau/embedding-api'
import { useEffect, useRef, useState } from 'react';
import { get_all_accounts, get_analysis, get_last_account,update_last_account } from '../services/analysis';
import Select from 'react-select-virtualized';
import TabsAndDashboards from "../components/Tabs_and_Dashboards"
import { useOktaAuth } from '@okta/okta-react';
import { parseJwt, get_OktaToken } from '../scripts/helpers';
import C360Logo from '../components/C360Logo'
import { AccountModalSelector } from '../components/AccountSelectorModal';

export default function New_Accout_Tabs() {
    //DEFAULT STATE VALUES
    const default_account_id="001U0000007OsdwIAC"
    const default_account_name="1-800 Flowers.com, Inc."
    const [accountName, setAccountName] = useState()
    const [accountID, setAccountID] = useState()
    const [spinner, setSpinner] = useState(true)
    const [accountSelectorStatus, setAccountSelectorStatus] = useState(true)
    const dashboardStatusRef = useRef({})
    const [dashboardList, setDashboardList] = useState([])
    const [options, setOptions] = useState([])
    const [trends, setTrends] = useState(null)
    const { authState } = useOktaAuth();
    const [isLoadingAccounts, setIsLoadingAccounts] = useState(true)
    const [initDashboardsLoaded, setInitDashboardsLoaded] = useState(false)
    const [initLoadSkipped, setInitLoadSkipped] = useState(false)
    const [proceedLoadAccount, setProceedLoadAccount]=useState(false)
    const [accountModalShow, setAccountModalShow] = useState(false)
    


    // ----------------------------FUNCTIONS ---------------------------------//

    function getUserEmail(){
        const oktaToken = get_OktaToken(authState)
        const parsedToken = parseJwt(oktaToken)
        const userEmail = parsedToken['sub']
        return userEmail
    }

    function getValueByKey(array, searchKey) {
        const foundItem = array.find(item => item.value === searchKey);
        return foundItem ? foundItem.label : undefined;
    }
    //Function to get the list of accounts and load them into the select
    async function updateAccountList() {

        setIsLoadingAccounts(true)
        const response = await get_all_accounts(authState)
        let all_accounts = []
        for (let i = 0; i < response.length; i++) {
            let account = {}
            account['value'] = response[i]['ACCOUNT_ID']
            account['label'] = response[i]['ACCOUNT_NAME']
            all_accounts.push(account)
        }
        all_accounts.sort((a, b) => {
            if (a.label > b.label) return 1;
            if (a.label < b.label) return -1;
            return 0;
        });
        setOptions(all_accounts)
        //get last account the user used
        const user_email=getUserEmail()
        const last_account = await get_last_account(authState, user_email)
        if (last_account.length != 0) {
            const account_id = last_account[0]['ACCOUNT_ID']
            const account_name = getValueByKey(all_accounts, account_id)
            if (account_name!=undefined){
                setAccountID(account_id)
                setAccountName(account_name)
            }
            else{
                alert("The previous account is not available anymore")
                setAccountModalShow(true)
            }
        }
        else {       
                setAccountModalShow(true)
        }
        setIsLoadingAccounts(false)
    }
    // function that updates the array when dashboards are loaded and unlocks the selector
    function updateStatus(id) {
        dashboardStatusRef.current[id] = 'loaded'
        if (!Object.values(dashboardStatusRef.current).includes("loading")) {
            // if (Object.values(dashboardStatusRef.current).includes("loaded")) {
            setAccountSelectorStatus(false)

        }
    }

    // ----------------------------USE EFFECTs ---------------------------------//

    //Use Effect Initial load
    useEffect(() => {
        updateAccountList()
    }, []);

    //Use Effect account id change
    useEffect(() => {
        setSpinner(true)
        if (accountID!="" & accountID!=undefined ){
            const user_email=getUserEmail()
            update_last_account(accountID,user_email,authState)
        }
        
        // counter avoids rerendering the dashboard on first load
        if (initLoadSkipped == true) {
            //block select and reset all dashboards to loading
            setAccountSelectorStatus(true)
            for (let i = 0; i < dashboardList.length; i++) {
                dashboardStatusRef.current[dashboardList[i]] = 'loading'
            }

            for (let i = 0; i < dashboardList.length; i++) {
                //for (let i = 0; i < 2; i++) {
                let dashboards = []
                dashboards[dashboardList[i]] = document.getElementById(dashboardList[i])
                let workbook = dashboards[dashboardList[i]].workbook;
                workbook.changeParameterValueAsync("Account_Id", accountID);
            }
        }
        if (isLoadingAccounts==false) {
            get_analysis(accountID, authState).then((response) => {
                setTrends(response)
                setSpinner(false)
            })
        }
        setInitLoadSkipped(true)


    }, [accountID]);

    useEffect(() => {
            // get all dashboard's ids 
            let all_dashboards = document.getElementsByTagName('tableau-viz')
            let dashboard_ids = []
            //PRESET ALL DASHBOARDS STATUS TO LOADING AND SABE DASHBOARD INFO
            for (let i = 0; i < all_dashboards.length; i++) {
                dashboard_ids.push(all_dashboards[i]['id'])
                dashboardStatusRef.current[all_dashboards[i]['id']] = 'loading'
                all_dashboards[i].addEventListener(TableauEventType.FirstInteractive, () => updateStatus(all_dashboards[i]['id']))
                all_dashboards[i].addEventListener(TableauEventType.ParameterChanged, () => updateStatus(all_dashboards[i]['id']))
                setDashboardList(dashboard_ids)
            }
            return () => {
                for (let i = 0; i < all_dashboards.length; i++) {
                    //EVENT LISTENER TO BE SURE ALL DASHBOARDS ARE LOADED BEFORE ALLOWING SELECTION
                    all_dashboards[i].removeEventListener(TableauEventType.FirstInteractive, () => updateStatus(all_dashboards[i]['id']));
                    all_dashboards[i].removeEventListener(TableauEventType.ParameterChanged, () => { updateStatus(all_dashboards[i]['id']) });

                }
            }  
    }, [initDashboardsLoaded]);

    //---------------------------------MAIN COMPONENT ------------------------------------//
    return (
        <>
            <div className="page_section">
                
                <div className='top-section'>
                    <div className='filters'>
                        <C360Logo />

                        <div className='select-box'>
                            <label className='select_label'>Select the Account: </label>

                            <Select options={options} isDisabled={accountSelectorStatus} onChange={e => {

                                setAccountName(e.label)
                                setAccountID(e.value)
                            }} classNamePrefix="custom-select" isLoading={isLoadingAccounts} loadingMessage={() => 'Loading accounts. Please wait...'} value={[{ value: accountID, label: accountName }]} />

                        </div>
                    </div>
                </div>
                <TabsAndDashboards spinner={spinner} trends={trends} accountID={accountID} isLoadingAccounts={isLoadingAccounts} setInitDashboardsLoaded={setInitDashboardsLoaded} />
            </div>
            <AccountModalSelector accountModalShow={accountModalShow} setAccountModalShow={setAccountModalShow} setAccountID={setAccountID} setAccountName={setAccountName} options={options} isLoadingAccounts={isLoadingAccounts}  setProceedLoadAccount={setProceedLoadAccount}></AccountModalSelector>
        </>

    )
}



