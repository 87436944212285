import axios from 'axios';
import { get_OktaToken, parseJwt } from '../scripts/helpers';
import endpoints from '../jsons/env_endpoints.json';

export async function send_feedback(authState,feedbackType,comment,page) {
    const oktaToken=get_OktaToken(authState)
    const parsedToken=parseJwt(oktaToken)
    const userEmail=parsedToken['sub']
    const enviroment = process.env.REACT_APP_DEPLOY_ENV;

    
    let url = endpoints[enviroment]['overall']
    let body = {
        user: "genesys_web_c360",
        token: "genesys_!jN0=ENMNpKx!w/X8rxDdL6pBk38FIoCDuicCBO8fi9CQdcfBBbHvQihllxfO3NYOKt/dHOlaCCvjPBs0vGt00MjgOen72nv1yL5nu-pTAN3eoTfrnlTA/UdM6qR5CDw8arDvRuuY7DTRGG63JhD=2ZW8W53dnqughWiJMNr-FzgJqTW7e50HoryP4HKDg=?ofybDTMgXsKn/dpgdm231rWfvK-ea0xeAVfht2j5Q?bEmzBZrxMOlJT=w0ZTVIzDG?R2WiwsbubDTyqTjNnwJQrT6KbRiLpura9sOF9QixRJ738uDEde8tHKWGOGVWl3ZlZ4FpZUmfOY=RGcJg2cSRaTCax4xHeZVW?6mkoVX0cuw4Ru0WT4=CUM9L77iu9Rnbn8IfpUcQ3KdwEdK30UON/XMNzWklEXIRTlJx2reXGuwAC8cT5Ogv0dJ/CgVyFU?6H?SLE!nxRl8UKp7B4xuJmI8-LhpLxMgKmdn1/1m-BwJON7wymG/8UadsQoL=RbZhTfttkEqF=KQ9q?ArikQPtxgN6mgtuJC-JpJ2TnGxPd54Ef/JTSvoRRgscCqslRmHQt=vTdVnR901R=3YB=cvyMlQtXpkEXNMtfU-8-E?kHAnuh8vCMcwdfi-0lef/PootGWMn2WEw3DAjGwKQr5d5yT=jaJ0SfzPnD9qQMe9iBgRU-eLfxM6MA0JLgwTejnGAeyPv9?zZe0Ou=g!hqda=X!J53iKpK68//os0zf/Bgp69WDzPpnmI=2moPom5uhS/eI1j8PPtiq4xe!gGC4Yp50SlD-80J/!xrNSuwXEoEYApo7=u8GJ5Ti5c!4=Cae7Aoan=rNfQpoPETHoC2Rgx=lLavP2gEPA7SK?1VTSBtQr?Wg5O9BpaxodMORN4DuVov-=o?hstJ2qfz-5ijRwH9gai2F!tFezJ-1?OUZ7dl3kfJW8SwdEk2bDO9czpmS?e-ZrM!05uT?sBU/WR99J0bJK3dkArG-jNZ!ehGfzX7qd0MqRnT3eXR",
        account_id: 'account_id',
        typecode: 6,
        oktaToken: oktaToken,
        feedbackType:feedbackType,
        comment:comment,
        page:page,
        userEmail:userEmail,
    }
    let response = await axios.post(url, body)
    if (response['status']==200){
        return true
    }
    return false;
}