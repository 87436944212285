import React, { createContext } from "react";

const Context = createContext();

function ContextProvider({ children }) {
  const oktaActivated=true;
  return (
    <Context.Provider
      value={{
        oktaActivated
      }}
    >
      {children}
    </Context.Provider>
  );
}

export { Context, ContextProvider };