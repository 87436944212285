import { TableauViz, TableauEventType, FilterType } from '@tableau/embedding-api'
import { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Account_Analysis from './Account_Analysis';
import data_json from "../jsons/account_view_tabs.json"
import { useLocation } from "react-router-dom"

const enviroment = process.env.REACT_APP_DEPLOY_ENV;
let data = data_json[enviroment]

const RenderedTabs = ({ visibleTab, setVisibleTab, dataJson }) => {
    const location = useLocation()

    const [allTabs, setAllTabs] = useState([])
    useEffect(() => {
        let all_tabs = []
        for (let i = 0; i < dataJson.length; i++) {
            if (dataJson[i].display == true) {
                all_tabs.push(
                    <Tab eventKey={dataJson[i].key} title={dataJson[i].title} />
                )
                if (dataJson[i].default == true) {
                    const page = location.search.replace("?tab=", '').split("&")[0]
                    if (page != "") {
                        setVisibleTab(page)
                    }
                    else {
                        setVisibleTab(dataJson[i].key)
                    }

                }
            }
        }
        setAllTabs(all_tabs)
    }, [dataJson])

    return (
        <Tabs
            activeKey={visibleTab}
            id="fill-tab-example"
            className="mb-3 tabs"
            onSelect={(activeKey) => {
                window.history.pushState("object or string", "tab", `/account_view?tab=${activeKey}`);
                setVisibleTab(activeKey)
            }}
            fill
        >
            {allTabs}
        </Tabs>)
}

const RenderDashboards = ({ dataJson, visibleTab, spinner, trends, accountID,isLoadingAccounts,setInitDashboardsLoaded }) => {
    const [all_dashboards, setAllDashboards] = useState([])
    useEffect(() => {
        if (isLoadingAccounts==false){

            const list_of_dashboards=[]
            for (let i = 0; i < dataJson.length; i++) {

                if (dataJson[i].display == true &
                    dataJson[i].tableauURL == "" & dataJson[i].key == 'overview'
                ) {
                    list_of_dashboards.push(
                        <div className={visibleTab == "overview" ? 'show_tab' : 'hidden_tab'}>
                            {spinner ? <><Spinner></Spinner><span className='spinner_text'>Loading ...</span></> : <Account_Analysis trends={trends} />}
                        </div>)
                }
    
                if (dataJson[i].display == true &
                    dataJson[i].tableauURL != ""
                ) {
                    list_of_dashboards.push(
                        <div className={visibleTab == dataJson[i].key ? 'show_tab' : 'hidden_tab'}>
                            <tableau-viz id={"tableauViz_" + dataJson[i].key}
                                src={dataJson[i].tableauURL} width={dataJson[i].width} height={dataJson[i].height} toolbar={dataJson[i].toolbar} hide-tabs={dataJson[i]['hide-tabs']}>
                                    <viz-parameter name='Account_Id' value={accountID}></viz-parameter>
                            </tableau-viz>
                        </div >
                    )
                }
            }
            setInitDashboardsLoaded(true)
            setAllDashboards(list_of_dashboards)
        }
    }, [accountID,visibleTab,spinner])

    return (
        <>
            {all_dashboards}
        </>)
}

export default function TabsAndDashboards({ spinner, trends, accountID,isLoadingAccounts,setInitDashboardsLoaded }) {
    //DEFAULT STATE VALUES
    const [visibleTab, setVisibleTab] = useState(null);
    const [dataJson, setDataJson] = useState(data);
    //---------------------------------MAIN COMPONENT ------------------------------------//
    return (
        <>
            <div className='tabs'>
                <RenderedTabs setVisibleTab={setVisibleTab} dataJson={dataJson} visibleTab={visibleTab} />
            </div>
            <div className='dashboard_container'>
                <RenderDashboards dataJson={dataJson} visibleTab={visibleTab} setVisibleTab={setVisibleTab} spinner={spinner} trends={trends} accountID={accountID} isLoadingAccounts={isLoadingAccounts} setInitDashboardsLoaded={setInitDashboardsLoaded} />
            </div >
        </>

    )
}



