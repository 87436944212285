import Card from 'react-bootstrap/Card';
import '../css/analysis_tab.css'

export default function Account_AttentionPoints({ analysis_Int_Values }) {
    let sentences=[]

    if (Math.round(+analysis_Int_Values['ACV_Variation']) > 1 && Math.round(+analysis_Int_Values['Revenue_Variation']) < 0) {
        sentences.push(<div className='analysis_trend'>   • Increase in ACV and decrease of revenue - Important to analyze the cause. </div>)
    }
    if (Math.round(+analysis_Int_Values['LICENSE_USAGE_RATE_Variation']) > 1 & Math.round(+analysis_Int_Values['LICENSE_USAGE_RATE_Trend']) > 1) {
        sentences.push(<div className='analysis_trend'>   • License Rate increasing MoM and with updward trend - Investigate possible subscription upgrade </div>)
    }
    if (Math.round(+analysis_Int_Values['USER_CNT_Variation']) < -1 & Math.round(+analysis_Int_Values['USER_CNT_Trend']) < -1) {
        sentences.push(<div className='analysis_trend'>   • User count decreasing and with downward trend  </div>)
    }
    if (Math.round(+analysis_Int_Values['NUMBER_OF_CREATED_CASES_Variation']) > Math.round(+analysis_Int_Values['NUMBER_OF_CLOSED_CASES_Variation'])) {
        sentences.push(<div className='analysis_trend'>   • More cases being created than closed - Attention  </div>)
    }

  
  
    return (
        <div className='analysis_card'>
            <div className='analysis_mom_trend_title'>Attention Points (Beta version - Under development)</div>
            <div className='accordion_trends'>
                {<>{sentences}</>}
            </div>
        </div>

    )
}